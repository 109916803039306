.menu-button {
  display: block;
  width: 100%;
  margin: 10px 0;
  padding: 18px 50px;

  cursor: pointer;

  background: #000000;
  color: #CCCCCC;

  border: 3px solid #CCCCCC;
  border-radius: 5px;

  white-space: nowrap;
  text-transform: uppercase;
  line-height: normal;
  font-size: 30px;
  text-align: center;
}

.menu-button:hover {
  background: #000000;
  color: #6FA8DC;
  border-color: #6FA8DC;
}

.menu-lobby-select {
  display: flex;

  height: 30px;
}

.menu-lobby-select input {
  flex: 1 1;
}

.logo {
  height: 80px;
}

.header {
  background-color: #222;
  padding: 20px;
  color: white;
  text-align: center;
}

.player_name {
  position: absolute;
  top: 10px;
  right: 10px;
}

.landing-nickname {
  display: block;
}

.loading {
  text-align: center;
}

.game {
  display: flex;
  flex-direction: row;

  width: 70%;
  height: 100%;
}

.board-wrapper {
  position: relative;
  flex: 1 1;

  /* Required so the the board does not force the box of the game to increase and thus not resize */
  overflow: hidden;
}

.failure-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(225, 225, 225, 0.4);

  display: flex;
  align-items: center;
  justify-content: center;
}

.failure-cover .failure-text {
  text-align: center;
  padding: 1em;
  background-color: #222;
  border: solid 3px black;
  border-radius: 10px;
}

.game-stats-container {
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
  padding: 10px 20px;
}

.game-stats {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 20px;
  background-color: #222;
  border-radius: 15px;
}

.game-player {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10px;

  border-radius: 10px;
}

.deets {
  position: absolute;
  top: 5px;
  left: 5px;
}

.lobby {
  display: flex;
  flex-direction: row;
  width: 70%;
}

.lobby-players {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.lobby-player-row {
  display: flex;
  flex-direction: row;
  flex: 1 1;
}

.lobby-player {
  display: flex;
  align-items: center;
  justify-content: center;

  flex: 1 1;
  height: 200px;
  margin: 5px;

  border-radius: 15px;
}

.p0-coloured {
  background-color: red;
}
.p1-coloured {
  background-color: purple;
}
.p2-coloured {
  background-color: blue;
}
.p3-coloured {
  background-color: yellow;
}


.lobby-sidebar {
  display: flex;
  flex-direction: column;

  padding: 10px 20px;
}

.lobby-settings {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1;

  padding: 20px;
  background-color: #222;
  border-radius: 15px;
}

* {
  box-sizing: border-box;
}

.hidden {
  display: none;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.body {
  flex: 1 1;
  background-color: #444;
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
}

.error-message {
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 10px 20px;

  background-color: red;
}

.error-message.error-closed {
  left: 10px;
  right: auto;
  right: initial;
}

.konva-test {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

