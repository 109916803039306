.lobby {
  display: flex;
  flex-direction: row;
  width: 70%;
}

.lobby-players {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.lobby-player-row {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.lobby-player {
  display: flex;
  align-items: center;
  justify-content: center;

  flex: 1;
  height: 200px;
  margin: 5px;

  border-radius: 15px;
}

.p0-coloured {
  background-color: red;
}
.p1-coloured {
  background-color: purple;
}
.p2-coloured {
  background-color: blue;
}
.p3-coloured {
  background-color: yellow;
}


.lobby-sidebar {
  display: flex;
  flex-direction: column;

  padding: 10px 20px;
}

.lobby-settings {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  padding: 20px;
  background-color: #222;
  border-radius: 15px;
}
