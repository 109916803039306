.logo {
  height: 80px;
}

.header {
  background-color: #222;
  padding: 20px;
  color: white;
  text-align: center;
}

.player_name {
  position: absolute;
  top: 10px;
  right: 10px;
}
