* {
  box-sizing: border-box;
}

.hidden {
  display: none;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.body {
  flex: 1;
  background-color: #444;
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
}

.error-message {
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 10px 20px;

  background-color: red;
}

.error-message.error-closed {
  left: 10px;
  right: initial;
}

.konva-test {
  width: 100%;
  height: 100%;
}
