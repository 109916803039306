.menu-button {
  display: block;
  width: 100%;
  margin: 10px 0;
  padding: 18px 50px;

  cursor: pointer;

  background: #000000;
  color: #CCCCCC;

  border: 3px solid #CCCCCC;
  border-radius: 5px;

  white-space: nowrap;
  text-transform: uppercase;
  line-height: normal;
  font-size: 30px;
  text-align: center;
}

.menu-button:hover {
  background: #000000;
  color: #6FA8DC;
  border-color: #6FA8DC;
}

.menu-lobby-select {
  display: flex;

  height: 30px;
}

.menu-lobby-select input {
  flex: 1;
}
