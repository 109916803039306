.game {
  display: flex;
  flex-direction: row;

  width: 70%;
  height: 100%;
}

.board-wrapper {
  position: relative;
  flex: 1;

  /* Required so the the board does not force the box of the game to increase and thus not resize */
  overflow: hidden;
}

.failure-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(225, 225, 225, 0.4);

  display: flex;
  align-items: center;
  justify-content: center;
}

.failure-cover .failure-text {
  text-align: center;
  padding: 1em;
  background-color: #222;
  border: solid 3px black;
  border-radius: 10px;
}

.game-stats-container {
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
  padding: 10px 20px;
}

.game-stats {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 20px;
  background-color: #222;
  border-radius: 15px;
}

.game-player {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10px;

  border-radius: 10px;
}

.deets {
  position: absolute;
  top: 5px;
  left: 5px;
}
